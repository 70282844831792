import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IPatientInfo } from "./examRecordInterface/UserInterfaces";
import { IIntro } from "./examRecordInterface/IntroModuleInterfaces";
import { IPastMedicalRecords } from "./examRecordInterface/PastMedicalRecordsInterfaces";
import { ISocialHistory } from "./examRecordInterface/SocialHistoryInterfaces";
import { IReviewOfRecords } from "./examRecordInterface/ReviewOfRecordsInterfaces";
import { IReviewOfSystem } from "./examRecordInterface/ReviewOfSystemInterfaces";
import { IPhysicalExaminationModule } from "./examRecordInterface/PhysicalExaminationModuleInterfaces";
import {
  LeftRightType,
  RangeOfMotionType,
} from "./examRecordInterface/RangeOfMotionInterfaces";
import {
  bodySectionsOptions,
  ISpinalMyotome,
  IStrengthExamData,
  IStrengthExamMeasurementsData,
} from "./examRecordInterface/StrengthExamInterfaces";
import { IOrthoExam } from "./examRecordInterface/OrthoExamInterfaces";
import { IConclusion } from "./examRecordInterface/ConclusionModuleInterfaces";

export interface ExamRecordState {
  patientInfo: IPatientInfo;
  introInfo: IIntro;
  pastMedicalRecordsData: IPastMedicalRecords;
  socialHistoryData: ISocialHistory;
  reviewOfRecordsData: IReviewOfRecords;
  reviewOfSystemData: IReviewOfSystem;
  physicalExaminationData: IPhysicalExaminationModule;
  bodySide: LeftRightType;
  patientRangeOfMotion: RangeOfMotionType[];
  strengthExamMeasurement: IStrengthExamData[];
  myotomeExamMeasurement: ISpinalMyotome[];
  orthopedictExamMeasurement: IOrthoExam;
  conclusionInfo: IConclusion;
}

const initialState: ExamRecordState = {
  patientInfo: {
    name: "",
    dob: "",
    phoneNumber: "",
    dateOfService: "",
  },
  introInfo: {
    description: "",
    subjectOfInterest: "",
  },
  pastMedicalRecordsData: {
    pastSurgericalProcedure: "",
    workInjury: "",
    autoRelatedInjury: "",
  },
  socialHistoryData: {
    text: "",
  },
  reviewOfRecordsData: {
    text: "",
  },
  reviewOfSystemData: {
    text: "",
  },
  physicalExaminationData: {
    text: "",
  },
  bodySide: "Left",
  patientRangeOfMotion: [],
  strengthExamMeasurement: [],
  myotomeExamMeasurement: [],
  orthopedictExamMeasurement: {},
  conclusionInfo: {
    impression: "",
    discussion: "",
  },
};

export const examRecordSlice = createSlice({
  name: "examRecord",
  initialState,
  reducers: {
    handlePatientInfo: (state, { payload }: PayloadAction<IPatientInfo>) => {
      state.patientInfo = payload;
    },
    handleIntroInfo: (state, { payload }: PayloadAction<IIntro>) => {
      state.introInfo = payload;
    },
    handlePastMedicalRecords: (
      state,
      { payload }: PayloadAction<IPastMedicalRecords>
    ) => {
      state.pastMedicalRecordsData = payload;
    },
    handleSocialHistory: (
      state,
      { payload }: PayloadAction<ISocialHistory>
    ) => {
      state.socialHistoryData = payload;
    },
    handleReviewOfRecords: (
      state,
      { payload }: PayloadAction<IReviewOfRecords>
    ) => {
      state.reviewOfRecordsData = payload;
    },
    handleReviewOfSystem: (
      state,
      { payload }: PayloadAction<IReviewOfSystem>
    ) => {
      state.reviewOfSystemData = payload;
    },
    handlePhysicalExaminationModuleData: (
      state,
      { payload }: PayloadAction<IPhysicalExaminationModule>
    ) => {
      state.physicalExaminationData = payload;
    },
    addMeasurement: (state, { payload }: PayloadAction<RangeOfMotionType>) => {
      state.patientRangeOfMotion = [...state.patientRangeOfMotion, payload];
    },
    removeMeasurement: (state, { payload }: PayloadAction<string>) => {
      state.patientRangeOfMotion = state.patientRangeOfMotion?.filter(
        (_, index) => {
          return index !== Number(payload);
        }
      );
    },
    handleAllRangeOfMotion: (
      state,
      { payload }: PayloadAction<RangeOfMotionType[]>
    ) => {
      state.patientRangeOfMotion = payload;
    },
    toggleBodySide: (state, { payload }: PayloadAction<boolean>) => {
      if (payload) {
        state.bodySide = "Right";
      } else {
        state.bodySide = "Left";
      }
    },
    handleStrengthExamMeasurement: (
      state,
      {
        payload: { dto, strengthType, bodySectionType },
      }: PayloadAction<{
        dto: IStrengthExamMeasurementsData;
        strengthType: keyof IStrengthExamMeasurementsData;
        bodySectionType: bodySectionsOptions;
      }>
    ) => {
      // check to see if state is empty
      if (!state.strengthExamMeasurement) {
        // set dto to state
        state.strengthExamMeasurement = [
          {
            bodySection: bodySectionType,
            strengthExamMeasurements: [dto],
          },
        ];

        // ---- else ----
      } else if (
        // check if dto body section entry is not already in the state
        !state.strengthExamMeasurement.find(
          (exam) => exam.bodySection === bodySectionType
        )
      ) {
        // add new entry and state to new list
        let newList = state.strengthExamMeasurement.concat([
          {
            bodySection: bodySectionType,
            strengthExamMeasurements: [dto],
          },
        ]);
        state.strengthExamMeasurement = newList; // set the new list to state

        //  ---- else -----
      } else {
        // get the section we are going to change
        let examSection = state.strengthExamMeasurement.find((exam) =>
          exam.bodySection === bodySectionType ? exam : null
        ); // there should only be one section

        // check to see if the dto we are adding is not already present
        if (
          examSection &&
          !examSection?.strengthExamMeasurements.find(
            (e) => e.bodySide === dto.bodySide && e.name === dto.name
          )
        ) {
          // add a new exam entry in the measurements
          let tempList = examSection?.strengthExamMeasurements.concat(dto);
          // create a new list of exams
          let newList = state.strengthExamMeasurement.map((exam) =>
            exam.bodySection === bodySectionType
              ? { ...exam, strengthExamMeasurements: tempList }
              : exam
          );
          state.strengthExamMeasurement = newList; // set the new exams to state
        } else if (examSection) {
          // if entry is found then create a new list with the value updated
          let tempList = examSection.strengthExamMeasurements.map((e) =>
            e.bodySide === dto.bodySide && e.name === dto.name
              ? { ...e, [strengthType]: dto[strengthType] }
              : e
          );
          // create a new list of exams
          let newList = state.strengthExamMeasurement.map((exam) =>
            exam.bodySection === bodySectionType
              ? { ...exam, strengthExamMeasurements: tempList }
              : exam
          );
          state.strengthExamMeasurement = newList; // set the new exams to state
        }
      }
    },
    handleAllStrengthExamMeasurement: (
      state,
      { payload }: PayloadAction<IStrengthExamData[]>
    ) => {
      state.strengthExamMeasurement = payload;
    },
    handleMyotomeExamMeasurement: (
      state,
      { payload }: PayloadAction<ISpinalMyotome>
    ) => {
      // check if there is data in the state
      if (!state.myotomeExamMeasurement) {
        // if no data in state
        state.myotomeExamMeasurement = [payload]; // set dto into state
      } else if (
        // if state is not empty
        // check if the there is no entry like the dto
        !state.myotomeExamMeasurement.find(
          (exam) => exam.spinalLevel === payload.spinalLevel
        )
      ) {
        const newList = state.myotomeExamMeasurement.concat(payload); // create new list with the new value added
        state.myotomeExamMeasurement = newList; // set the new list to state
      } else {
        // if there is an entry like the dto
        // create a new list with the value updated
        const newList = state.myotomeExamMeasurement.map((exam) =>
          exam.spinalLevel === payload.spinalLevel
            ? { ...exam, grade: payload.grade }
            : exam
        );
        state.myotomeExamMeasurement = newList; // set the new list to state
      }
    },
    handleAllMyotomeExamMeasurement: (
      state,
      { payload }: PayloadAction<ISpinalMyotome[]>
    ) => {
      state.myotomeExamMeasurement = payload;
    },
    handleOrthoExamMeasurement: (
      state,
      { payload }: PayloadAction<IOrthoExam>
    ) => {
      var tempOrtho = state.orthopedictExamMeasurement;
      const dtoKey = Object.keys(payload)[0];
      const dtoValue = payload[dtoKey][0];

      // check to see if the store is empty
      if (!tempOrtho) {
        // Object.entries(dto).forEach(([key, value]) => { tempOrtho[key] = value })
        tempOrtho = payload; // add value to store
      }

      // check to see if the store includes current key
      if (!tempOrtho[dtoKey]) {
        tempOrtho[dtoKey] = [dtoValue];
      }

      // check to see if store includes key entry but doesnt include name or body site
      if (
        tempOrtho[dtoKey] &&
        !tempOrtho[dtoKey].filter(
          (value) =>
            value.name === dtoValue.name && value.bodySide === dtoValue.bodySide
        ).length
      ) {
        tempOrtho[dtoKey].push(dtoValue);
      }

      // get only the positive values and reduce to an object
      const positiveOrthoExams = Object.entries(tempOrtho)
        .map(([key, value]) => {
          return {
            key: key,
            values: value.filter((exams) => exams.isPositive === true),
          };
        })
        .reduce((obj, item) => ({ ...obj, [item.key]: item.values }), {});

      state.orthopedictExamMeasurement = positiveOrthoExams;
    },
    handleConclusionInfo: (state, { payload }: PayloadAction<IConclusion>) => {
      state.conclusionInfo = payload;
    },
    clearAllExamRecord: (state) => {
      state.patientInfo = {
        name: "",
        dob: "",
        phoneNumber: "",
        dateOfService: "",
      };
      state.introInfo = {
        description: "",
        subjectOfInterest: "",
      };
      state.pastMedicalRecordsData = {
        pastSurgericalProcedure: "",
        workInjury: "",
        autoRelatedInjury: "",
      };
      state.socialHistoryData = {
        text: "",
      };
      state.reviewOfRecordsData = {
        text: "",
      };
      state.reviewOfSystemData = {
        text: "",
      };
      state.physicalExaminationData = {
        text: "",
      };
      state.bodySide = "Left";
      state.patientRangeOfMotion = [];
      state.strengthExamMeasurement = [];
      state.myotomeExamMeasurement = [];
      state.orthopedictExamMeasurement = {};
      state.conclusionInfo = {
        impression: "",
        discussion: "",
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  handlePatientInfo,
  handleIntroInfo,
  handlePastMedicalRecords,
  handleSocialHistory,
  handleReviewOfRecords,
  handleReviewOfSystem,
  handlePhysicalExaminationModuleData,
  addMeasurement,
  removeMeasurement,
  handleAllRangeOfMotion,
  toggleBodySide,
  handleStrengthExamMeasurement,
  handleAllStrengthExamMeasurement,
  handleMyotomeExamMeasurement,
  handleAllMyotomeExamMeasurement,
  handleOrthoExamMeasurement,
  handleConclusionInfo,
  clearAllExamRecord,
} = examRecordSlice.actions;

export default examRecordSlice.reducer;
