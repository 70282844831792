import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Organization } from "./organizationType";

export interface organizationState {
  selectedOrganization: Organization | null;
  organizations: Organization[];
  currentPage?: string;
  totalPages?: number;
}

const initialState: organizationState = {
  selectedOrganization: null,
  organizations: [],
  currentPage: "1",
  totalPages: 0,
};
// Add pagination for organization lists
export const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    setOrganizations: (
      state,
      {
        payload: { currentPage, items, totalPages },
      }: PayloadAction<{
        currentPage?: string;
        items?: Organization[];
        totalPages?: number;
      }>
    ) => {
      state.currentPage = currentPage ?? "1";
      state.organizations = [...state.organizations, ...(items ?? [])];
      state.totalPages = totalPages ?? 0;
    },
    selectOrganization: (state, { payload }: PayloadAction<Organization>) => {
      state.selectedOrganization = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setOrganizations, selectOrganization } =
  organizationSlice.actions;

export default organizationSlice.reducer;
