import React, { useRef, useState } from "react";
import { IonButton, IonContent, IonModal } from "@ionic/react";

/** Component */
import List from "../../../components/List/List";
import CreateOrganizationForm from "../createOrganizationForm/createOrganizationForm";
import { useBrowseOrganizationQuery } from "../organizationApi";

/** Style */
import styles from "./organizationsMenu.module.css";
import { useDispatch } from "react-redux";
import { selectOrganization } from "../organizationSlice";
import { Organization } from "../organizationType";

const OrganizationsMenu: React.FC = () => {
  const modal = useRef<HTMLIonModalElement>(null);
  const input = useRef<HTMLIonInputElement>(null);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const { data, isLoading, error } = useBrowseOrganizationQuery({
    page: page,
    results: 10,
  });

  const openModal = () => {
    modal.current?.dismiss();
  };

  const closeModal = () => {
    modal.current?.dismiss(input.current?.value, "confirm");
  };

  const onNext = () => {
    if (page < (data?.totalPages as number)) {
      setPage((page) => page + 1);
    }
  };

  const onPrevious = () => {
    if (page > 1) {
      setPage((page) => page - 1);
    }
  };

  const onRowButtonClick = (item: Organization) => {
    dispatch(selectOrganization(item));
  };

  return (
    <IonContent className="ion-padding">
      <div className={styles.container}>
        <div>
          <List
            label="organizations"
            data={data?.items}
            height={window.innerHeight / 1.5}
            isLoading={isLoading}
            isEmpty={data?.items?.length === 0}
            error={error}
            hasNext={page < (data?.totalPages as number)}
            hasPrevious={page > 1}
            onNext={onNext}
            onPrevious={onPrevious}
            onRowButtonClick={onRowButtonClick}
          />
        </div>
        <IonButton
          id="open-modal"
          mode="ios"
          color="primary"
          shape="round"
          className={styles.button}
        >
          Create
        </IonButton>
      </div>
      <IonModal ref={modal} trigger="open-modal">
        <CreateOrganizationForm {...{ openModal, closeModal }} />
      </IonModal>
    </IonContent>
  );
};

export default OrganizationsMenu;
