import { Organization } from "../organization/organizationType";

export enum Role {
  backOfficeAdmin = "backOfficeAdmin",
  doctor = "doctor",
  patient = "patient",
}
export interface User {
  id?: string;
  tenantId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  phoneNumber?: string;
  role?: Role;
  address?: string;
  birthDate?: string;
  insuranceId?: string;
  licenseNumber?: number;
  organizationId?: string;
  organization?: Organization;
}
export interface BrowseUserResponse {
  currentPage?: string;
  items?: User[];
  resultsPerPage?: number;
  totalPages?: number;
  totalResults?: number;
}
export interface BrowseUserRequest {
  organizationId?: string;
  role?: string;
  page: number;
  results: number;
}
