import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../../redux/store";
import { Insurance } from "./insuranceType";

const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT as string;

export const insuranceApi = createApi({
  reducerPath: "insuranceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/insurance`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.accessToken;
      if (token) {
        headers.set("authentication", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    browseInsurance: builder.query<Insurance[], void>({
      query: () => `browse-insurances`,
    }),
    createInsurance: builder.mutation<any, any>({
      query: (body) => ({
        url: `create-insurance`,
        method: "POST",
        body,
      }),
    }),
    updateInsurance: builder.mutation<any, any>({
      query: (body) => ({
        url: `update-insurance`,
        method: "POST",
        body,
      }),
    }),
    deleteInsurance: builder.mutation<any, any>({
      query: (body) => ({
        url: `delete-insurance`,
        method: "DELETE",
        body,
      }),
    }),
  }),
});

export const {
  useBrowseInsuranceQuery,
  useCreateInsuranceMutation,
  useUpdateInsuranceMutation,
  useDeleteInsuranceMutation,
} = insuranceApi;
