import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { MenuTab } from "./menuType";

export interface MenuState {
  selectedMenuTab?: MenuTab;
}

const initialState: MenuState = {
  selectedMenuTab: undefined,
};

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    selectMenuTab: (state, action: PayloadAction<MenuTab>) => {
      state.selectedMenuTab = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { selectMenuTab } = menuSlice.actions;

export default menuSlice.reducer;
