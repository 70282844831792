import React, { lazy, Suspense } from "react";
import {
  IonApp,
  IonRouterOutlet,
  IonSpinner,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

/* Theme variables */
import "./theme/variables.css";
import "./App.css";

/** Ionic CSS */
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/** Pages */
// import PhysicalExam from "./pages/PhysicalExam/PhysicalExam";
// import PdfReport from "./pages/PdfReport/PdfReport";
import Login from "./pages/Login/Login";
import Signup from "./pages/Signup/Signup";
import Dashboard from "./pages/Dashboard/Dashboard";
import Onboarding from "./pages/Onboarding/Onboarding";
import ProtectedRoute from "./features/auth/protectedRoute";
import PatientDetail from "./pages/PatientDetail/PatientDetail";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import ExamRecord from "./pages/ExamRecord/ExamRecord";

/** Redux */
import { store } from "./redux/store";

setupIonicReact({});

let persistor = persistStore(store);
const PhysicalExam = lazy(() => import("./pages/PhysicalExam/PhysicalExam"));
const PdfReport = lazy(() => import("./pages/PdfReport/PdfReport"));

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <IonApp>
          <IonReactRouter>
            <IonRouterOutlet>
              <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/signup" component={Signup} />
                <Route
                  exact
                  path="/set-password/:id"
                  component={ResetPassword}
                />
                <ProtectedRoute path="/onboarding">
                  <Route exact path="/onboarding" component={Onboarding} />
                </ProtectedRoute>
                <ProtectedRoute path="/">
                  <Route exact path="/" component={Dashboard} />
                  <Route
                    exact
                    path="/patient-detail/:id"
                    component={PatientDetail}
                  />
                  <Route
                    exact
                    path="/patient-detail/:id/exam-record/:id"
                    component={ExamRecord}
                  />
                  <Suspense
                    fallback={
                      <IonSpinner
                        name="crescent"
                        color="primary"
                        className="loading"
                      />
                    }
                  >
                    <Route path="/exams" component={PhysicalExam} />
                    <Route exact path="/report" component={PdfReport} />
                  </Suspense>
                </ProtectedRoute>
              </Switch>
            </IonRouterOutlet>
          </IonReactRouter>
        </IonApp>
      </PersistGate>
    </Provider>
  );
};

export default App;
