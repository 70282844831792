import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonText,
} from "@ionic/react";
import { checkmark } from "ionicons/icons";
import { useDispatch } from "react-redux";

/** Components */
import { useBrowseExamRecordDetailQuery } from "../../features/examRecord/examRecordApi";
import {
  PastMedicalRecordsDefaultStatements,
  PastMedicalRecordsOptionsArray,
} from "../../data/PastMedicalRecordsDefaultStatements";
import {
  handleAllMyotomeExamMeasurement,
  handleAllRangeOfMotion,
  handleAllStrengthExamMeasurement,
  handleConclusionInfo,
  handleIntroInfo,
  handleOrthoExamMeasurement,
  handlePastMedicalRecords,
  handlePatientInfo,
  handlePhysicalExaminationModuleData,
  handleReviewOfRecords,
  handleReviewOfSystem,
  handleSocialHistory,
} from "../../features/examRecord/examRecordSlice";

/** Type */
import {
  ISocialHistory,
  socialHistoryDefaultStatement,
} from "../../features/examRecord/examRecordInterface/SocialHistoryInterfaces";
import {
  IReviewOfSystem,
  reviewOfSystemDefaultStatements,
} from "../../features/examRecord/examRecordInterface/ReviewOfSystemInterfaces";
import {
  IPhysicalExaminationModule,
  physicalExaminationModuleDefaultStatements,
} from "../../features/examRecord/examRecordInterface/PhysicalExaminationModuleInterfaces";
import { RangeOfMotionType } from "../../features/examRecord/examRecordInterface/RangeOfMotionInterfaces";
import {
  ISpinalMyotome,
  IStrengthExamData,
  IStrengthExamMeasurementsData,
} from "../../features/examRecord/examRecordInterface/StrengthExamInterfaces";
import {
  IOrthoExam,
  IOrthoExamMeasurementsData,
} from "../../features/examRecord/examRecordInterface/OrthoExamInterfaces";
import { IPatientInfo } from "../../features/examRecord/examRecordInterface/UserInterfaces";
import { IPastMedicalRecords } from "../../features/examRecord/examRecordInterface/PastMedicalRecordsInterfaces";
import { IReviewOfRecords } from "../../features/examRecord/examRecordInterface/ReviewOfRecordsInterfaces";
import { IConclusion } from "../../features/examRecord/examRecordInterface/ConclusionModuleInterfaces";
import { IIntro } from "../../features/examRecord/examRecordInterface/IntroModuleInterfaces";

/** Graphics */
import Cervical from "../../graphics/StrengthExamAssets/Cervical Spine.jpg";
import Shoulder from "../../graphics/StrengthExamAssets/Shoulder.jpg";
import Elbow from "../../graphics/StrengthExamAssets/Elbow.jpg";
import Wrist from "../../graphics/StrengthExamAssets/Wrist.jpg";
import Lumbar from "../../graphics/StrengthExamAssets/Lumbar.jpg";
import Hip from "../../graphics/StrengthExamAssets/Hip.jpg";
import Knee from "../../graphics/StrengthExamAssets/Knee.jpg";
import Ankle from "../../graphics/StrengthExamAssets/Ankle.jpg";

const StrengthImageComponents = {
  Cervical: Cervical,
  Shoulder: Shoulder,
  Elbow: Elbow,
  Wrist: Wrist,
  Lumbar: Lumbar,
  Hip: Hip,
  Knee: Knee,
  Ankle: Ankle,
};

const ExamRecord: React.FC = () => {
  const {
    state: { id },
  }: { state: { id: string } } = useLocation();
  const { data: examRecordDetail } = useBrowseExamRecordDetailQuery({
    id,
  });
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    examRecordDetail?.data?.patientInfo &&
      dispatch(
        handlePatientInfo(examRecordDetail?.data?.patientInfo as IPatientInfo)
      );
    examRecordDetail?.data?.introInfo &&
      dispatch(handleIntroInfo(examRecordDetail?.data?.introInfo as IIntro));
    examRecordDetail?.data?.pastMedicalRecordsData &&
      dispatch(
        handlePastMedicalRecords(
          examRecordDetail?.data?.pastMedicalRecordsData as IPastMedicalRecords
        )
      );
    examRecordDetail?.data?.socialHistoryData &&
      dispatch(
        handleSocialHistory(
          examRecordDetail?.data?.socialHistoryData as ISocialHistory
        )
      );
    examRecordDetail?.data?.reviewOfRecordsData &&
      dispatch(
        handleReviewOfRecords(
          examRecordDetail?.data?.reviewOfRecordsData as IReviewOfRecords
        )
      );
    examRecordDetail?.data?.reviewOfSystemData &&
      dispatch(
        handleReviewOfSystem(
          examRecordDetail?.data?.reviewOfSystemData as IReviewOfSystem
        )
      );
    examRecordDetail?.data?.physicalExaminationData &&
      dispatch(
        handlePhysicalExaminationModuleData(
          examRecordDetail?.data
            ?.physicalExaminationData as IPhysicalExaminationModule
        )
      );
    examRecordDetail?.data?.patientRangeOfMotion &&
      dispatch(
        handleAllRangeOfMotion(
          examRecordDetail?.data?.patientRangeOfMotion as RangeOfMotionType[]
        )
      );
    examRecordDetail?.data?.strengthExamMeasurement &&
      dispatch(
        handleAllStrengthExamMeasurement(
          examRecordDetail?.data?.strengthExamMeasurement as IStrengthExamData[]
        )
      );
    examRecordDetail?.data?.myotomeExamMeasurement &&
      dispatch(
        handleAllMyotomeExamMeasurement(
          examRecordDetail?.data?.myotomeExamMeasurement as ISpinalMyotome[]
        )
      );
    examRecordDetail?.data?.orthopedictExamMeasurement &&
      Object.keys(examRecordDetail?.data?.orthopedictExamMeasurement).length >
        0 &&
      dispatch(
        handleOrthoExamMeasurement(
          examRecordDetail?.data?.orthopedictExamMeasurement as IOrthoExam
        )
      );
    examRecordDetail?.data?.conclusionInfo &&
      dispatch(
        handleConclusionInfo(
          examRecordDetail?.data?.conclusionInfo as IConclusion
        )
      );
  }, [
    examRecordDetail?.data?.conclusionInfo,
    examRecordDetail?.data?.myotomeExamMeasurement,
    examRecordDetail?.data?.orthopedictExamMeasurement,
    examRecordDetail?.data?.pastMedicalRecordsData,
    examRecordDetail?.data?.patientInfo,
    examRecordDetail?.data?.patientRangeOfMotion,
    examRecordDetail?.data?.physicalExaminationData,
    examRecordDetail?.data?.reviewOfRecordsData,
    examRecordDetail?.data?.reviewOfSystemData,
    examRecordDetail?.data?.socialHistoryData,
    examRecordDetail?.data?.strengthExamMeasurement,
    examRecordDetail?.data?.introInfo,
    id,
  ]);

  const renderStrengthExamData = (data: IStrengthExamMeasurementsData[]) => {
    return data.map((d, index) => {
      return (
        <div
          key={index}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            margin: "10px 0",
            flexGrow: 1,
            fontSize: "11px",
          }}
        >
          <IonLabel>
            The patient performed a muscle resistance test which revealed a{" "}
            <b>{d.normalStrength}% increase</b> in strength of the{" "}
            <b>{d.name}</b>
            {d.bodySide ? `, on the ${d.bodySide} side,` : null} towards normal
          </IonLabel>
          <IonLabel>
            The patient performed a muscle resistance test which revealed a{" "}
            <b>{d.strongStrength} lbs increase</b> in strength of the{" "}
            <b>{d.name}</b>
            {d.bodySide ? `, on the ${d.bodySide} side,` : null} from normal
          </IonLabel>
        </div>
      );
    });
  };

  const renderOrthoExamResults = (data: IOrthoExamMeasurementsData[]) => {
    return data?.map((d, index) => {
      return (
        <div
          key={index}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            margin: "10px 0",
            flexGrow: 1,
            fontSize: "11px",
          }}
        >
          <IonLabel>
            {d.bodySide} <b>{d.name}</b> test indicates{" "}
            <b>{d.isPositive ? "positive" : "negative"}</b>{" "}
            {d.testedFor.join(", ")} involvement.
          </IonLabel>
        </div>
      );
    });
  };

  return (
    <IonPage>
      <IonContent>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel>
                {`name: ${examRecordDetail?.data?.patientInfo.name}`}
              </IonLabel>
              <IonLabel>
                {`DOB: ${examRecordDetail?.data?.patientInfo.dob}`}
              </IonLabel>
              <IonLabel>
                {`Date of Service: ${examRecordDetail?.data?.patientInfo.dateOfService}`}
              </IonLabel>
            </IonItem>

            <IonItem>
              <IonLabel>
                {`Subject of Interest: ${examRecordDetail?.data?.introInfo.subjectOfInterest}`}
              </IonLabel>
              <IonLabel>
                {`Description: ${examRecordDetail?.data?.introInfo.description}`}
              </IonLabel>
            </IonItem>

            <IonItem>
              <IonLabel>
                <IonText color="primary" style={{ fontWeight: "bold" }}>
                  Past Medical Records:
                </IonText>
                {PastMedicalRecordsOptionsArray?.map(
                  (pastRecordOptions, index) => (
                    <IonLabel key={index}>
                      {pastRecordOptions.label}:{" "}
                      <IonLabel>
                        {examRecordDetail?.data?.pastMedicalRecordsData &&
                        examRecordDetail?.data?.pastMedicalRecordsData[
                          pastRecordOptions.key
                        ]
                          ? examRecordDetail?.data?.pastMedicalRecordsData[
                              pastRecordOptions.key
                            ]
                          : PastMedicalRecordsDefaultStatements[
                              pastRecordOptions.key
                            ]}
                      </IonLabel>
                    </IonLabel>
                  )
                )}
              </IonLabel>
            </IonItem>

            <IonItem>
              <IonLabel>
                <IonText color="primary" style={{ fontWeight: "bold" }}>
                  Social History:{" "}
                </IonText>
                {examRecordDetail?.data?.socialHistoryData &&
                examRecordDetail?.data?.socialHistoryData.text
                  ? examRecordDetail?.data?.socialHistoryData.text
                  : socialHistoryDefaultStatement.text}
              </IonLabel>
            </IonItem>

            <IonItem>
              <IonLabel>
                <IonText color="primary" style={{ fontWeight: "bold" }}>
                  Review Of Records:{" "}
                </IonText>
                {examRecordDetail?.data?.reviewOfRecordsData?.text}
              </IonLabel>
            </IonItem>

            <IonItem>
              <IonLabel>
                <IonText color="primary" style={{ fontWeight: "bold" }}>
                  Review Of System:{" "}
                </IonText>
                {examRecordDetail?.data?.reviewOfSystemData &&
                examRecordDetail?.data?.reviewOfSystemData?.text ? (
                  <IonLabel>
                    {examRecordDetail?.data?.reviewOfSystemData?.text}
                  </IonLabel>
                ) : (
                  Object.entries(reviewOfSystemDefaultStatements)?.map(
                    ([key, value]) => {
                      return (
                        <IonLabel key={key}>
                          {key}: <IonLabel>{value}</IonLabel>
                        </IonLabel>
                      );
                    }
                  )
                )}
              </IonLabel>
            </IonItem>

            <IonItem>
              <IonLabel>
                <IonText color="primary" style={{ fontWeight: "bold" }}>
                  Physical Examination:{" "}
                </IonText>
                {examRecordDetail?.data?.physicalExaminationData &&
                examRecordDetail?.data?.physicalExaminationData?.text ? (
                  <IonLabel>
                    <IonLabel>
                      {examRecordDetail?.data?.physicalExaminationData?.text}
                    </IonLabel>
                  </IonLabel>
                ) : (
                  Object.entries(
                    physicalExaminationModuleDefaultStatements
                  ).map(([key, value]) => {
                    return (
                      <IonLabel key={key}>
                        {key}: <IonLabel>{value}</IonLabel>
                      </IonLabel>
                    );
                  })
                )}
              </IonLabel>
            </IonItem>

            <IonItem>
              <IonLabel>
                <IonText color="primary" style={{ fontWeight: "bold" }}>
                  Range of Motion Exam Report:{" "}
                </IonText>
                {examRecordDetail?.data?.patientRangeOfMotion
                  ? examRecordDetail?.data?.patientRangeOfMotion?.map(
                      (s: RangeOfMotionType, key: number) => {
                        return Object.entries(s)?.map((value) => {
                          return (
                            <IonLabel key={key + Math.random()}>
                              <IonLabel>{value[0]}</IonLabel>
                              <IonLabel>{value[1]}</IonLabel>
                            </IonLabel>
                          );
                        });
                      }
                    )
                  : null}
              </IonLabel>
            </IonItem>

            <IonItem>
              <IonLabel>
                <IonText color="primary" style={{ fontWeight: "bold" }}>
                  Strength Exam Report:{" "}
                </IonText>
                {examRecordDetail?.data?.strengthExamMeasurement
                  ? (
                      examRecordDetail as any
                    )?.data?.strengthExamMeasurement.map(
                      (s: IStrengthExamData, key: number) => {
                        return (
                          <div key={key + Math.random()}>
                            <IonLabel
                              style={{ fontSize: "18px", padding: "10px 0" }}
                            >
                              {s.bodySection}:
                            </IonLabel>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <img
                                alt="img"
                                style={{ width: "80px" }}
                                src={
                                  StrengthImageComponents[
                                    s.bodySection.split(
                                      " "
                                    )[0] as keyof typeof StrengthImageComponents
                                  ]
                                }
                              />
                            </div>
                            {renderStrengthExamData(s.strengthExamMeasurements)}
                          </div>
                        );
                      }
                    )
                  : null}
              </IonLabel>
            </IonItem>

            <IonItem>
              <IonLabel>
                <IonText color="primary" style={{ fontWeight: "bold" }}>
                  Myotomes Exam Report:{" "}
                </IonText>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingTop: 8,
                    paddingBottom: 8,
                    fontSize: "12px",
                    gap: "4vw",
                    fontWeight: "bold",
                  }}
                >
                  <IonLabel style={{ width: "27%" }}>Spinal Level</IonLabel>
                  <IonLabel style={{ width: "33%" }}>Myotome</IonLabel>
                  <IonLabel style={{ width: "33%" }}>Grade</IonLabel>
                </div>
                {examRecordDetail?.data?.myotomeExamMeasurement.map(
                  (row, i) => (
                    <div
                      key={i}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        paddingTop: 8,
                        paddingBottom: 8,
                        fontSize: "12px",
                        gap: "4vw",
                      }}
                    >
                      <IonLabel style={{ width: "27%" }}>
                        {row.spinalLevel}
                      </IonLabel>
                      <IonLabel style={{ width: "33%" }}>
                        {row.myotomes}
                      </IonLabel>
                      <IonLabel style={{ width: "33%" }}>
                        {row.grade} / 5
                      </IonLabel>
                    </div>
                  )
                )}
              </IonLabel>
            </IonItem>

            <IonItem>
              <IonLabel>
                <IonText color="primary" style={{ fontWeight: "bold" }}>
                  Orthopedic Exam Report:{" "}
                </IonText>
                {examRecordDetail?.data
                  ? Object.entries(
                      examRecordDetail?.data?.orthopedictExamMeasurement
                    ).map(([section, values], key) => {
                      return (
                        <div key={key + Math.random()}>
                          <IonLabel
                            style={{ fontSize: "18px", padding: "10px 0" }}
                          >
                            {section}:
                          </IonLabel>
                          {renderOrthoExamResults(values)}
                        </div>
                      );
                    })
                  : null}
              </IonLabel>
            </IonItem>

            <IonItem>
              <IonLabel>
                <IonText color="primary" style={{ fontWeight: "bold" }}>
                  Conclusion:{" "}
                </IonText>
                <IonLabel>
                  Impression:{" "}
                  <IonLabel>
                    {examRecordDetail?.data?.conclusionInfo.impression}
                  </IonLabel>
                </IonLabel>
                <IonLabel>
                  Discussion:{" "}
                  <IonLabel>
                    {examRecordDetail?.data?.conclusionInfo.discussion}
                  </IonLabel>
                </IonLabel>
              </IonLabel>
            </IonItem>
          </IonCol>
        </IonRow>

        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton
            onClick={() => history.push("/report")}
            className="done-button"
          >
            <IonIcon src={checkmark} />
          </IonFabButton>
        </IonFab>
      </IonContent>
    </IonPage>
  );
};

export default ExamRecord;
