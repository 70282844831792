import { IPastMedicalRecords } from "../features/examRecord/examRecordInterface/PastMedicalRecordsInterfaces";

export const PastMedicalRecordsDefaultStatements: IPastMedicalRecords = {
  pastSurgericalProcedure: "Denies any past suregical procedures.",
  workInjury: "Denies any work related injuries.",
  autoRelatedInjury: "Denies any auto related injuries.",
};

interface IPastMedicalRecordsOptionsArray {
  label: "Past Surgical Procedure" | "Work Injury" | "Auto Related Injury";
  key: "pastSurgericalProcedure" | "workInjury" | "autoRelatedInjury";
}

export const PastMedicalRecordsOptionsArray: IPastMedicalRecordsOptionsArray[] =
  [
    {
      label: "Past Surgical Procedure",
      key: "pastSurgericalProcedure",
    },
    {
      label: "Work Injury",
      key: "workInjury",
    },
    {
      label: "Auto Related Injury",
      key: "autoRelatedInjury",
    },
  ];
