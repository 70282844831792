import React, { useState } from "react";
import {
  IonRow,
  IonCol,
  IonPage,
  IonLabel,
  IonInput,
  IonText,
  IonButton,
  IonLoading,
  IonContent,
  IonButtons,
  IonIcon,
} from "@ionic/react";
import { eyeOutline, eyeOffOutline } from "ionicons/icons";
import { useForm, SubmitHandler } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";

/** Component */
import { useSignupMutation } from "../../features/auth/authApi";
import { logout, setCredentials } from "../../features/auth/authSlice";
import { setTenantId } from "../../features/tenant/tenantSlice";
import { RootState } from "../../redux/store";

/** Style */
import styles from "./Signup.module.css";

type Inputs = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
};

const Signup: React.FC = () => {
  const [isRevealPassword, setIsRevealPassword] = useState(false);
  const refreshToken = useSelector(
    (state: RootState) => state.auth.accessToken
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [signup, { isLoading, error: signupError }] = useSignupMutation();

  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    const { password, confirmPassword } = data;

    if (password !== confirmPassword) {
      alert("Passwords don't match");
    } else {
      try {
        refreshToken && dispatch(logout());
        const response = await signup(data).unwrap();
        if (response) {
          let decoded: any = jwt_decode(response?.accessToken);
          const data = {
            userId: decoded?.userId,
            accessToken: response.accessToken,
            refreshToken: response.refreshToken,
          };
          dispatch(setCredentials(data));
          dispatch(setTenantId({ tenantId: decoded?.tenantId }));
          push("/onboarding");
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  return (
    <IonPage>
      <IonContent>
        <IonRow className={styles.container}>
          <IonCol className={styles.col}>
            <IonRow>
              <IonText className={styles.title}>Join Americana Cloud</IonText>
            </IonRow>
            <IonRow>
              <IonText className={styles.desc}>
                First steps to create your account
              </IonText>
            </IonRow>
            <form onSubmit={handleSubmit(onSubmit)}>
              <IonRow className={styles.formItem}>
                <IonCol>
                  <IonLabel className={styles.label}>Firstname</IonLabel>
                  <IonInput
                    type="text"
                    className={styles.input}
                    placeholder="Choose a firstname"
                    // @ts-ignore: Unreachable code error
                    {...register("firstName", {
                      required: "Firstname is Required!",
                    })}
                    name="firstName"
                  />
                  <IonText color="danger">{errors?.firstName?.message}</IonText>
                </IonCol>
              </IonRow>
              <IonRow className={styles.formItem}>
                <IonCol>
                  <IonLabel className={styles.label}>Lastname</IonLabel>
                  <IonInput
                    type="text"
                    className={styles.input}
                    placeholder="Choose a lastname"
                    // @ts-ignore: Unreachable code error
                    {...register("lastName", {
                      required: "Lastname is Required!",
                    })}
                    name="lastName"
                  />
                  <IonText color="danger">{errors?.lastName?.message}</IonText>
                </IonCol>
              </IonRow>
              <IonRow className={styles.formItem}>
                <IonCol>
                  <IonLabel className={styles.label}>Email</IonLabel>
                  <IonInput
                    type="email"
                    autocomplete="email"
                    className={styles.input}
                    placeholder="Enter your email"
                    // @ts-ignore: Unreachable code error
                    {...register("email", { required: "Email is Required!" })}
                    name="email"
                  />
                  <IonText color="danger">{errors?.email?.message}</IonText>
                </IonCol>
              </IonRow>
              <IonRow className={styles.formItem}>
                <IonCol>
                  <IonLabel className={styles.label}>Password</IonLabel>
                  <IonRow>
                    <IonInput
                      type={isRevealPassword ? "text" : "password"}
                      className={styles.input}
                      autocomplete="new-password"
                      placeholder="Enter your password"
                      // @ts-ignore: Unreachable code error
                      {...register("password", {
                        required: "Password is Required!",
                      })}
                      name="password"
                    />
                    <IonButtons>
                      <IonButton
                        onClick={() => setIsRevealPassword(!isRevealPassword)}
                      >
                        <IonIcon
                          slot="icon-only"
                          icon={isRevealPassword ? eyeOutline : eyeOffOutline}
                        ></IonIcon>
                      </IonButton>
                    </IonButtons>
                  </IonRow>
                  <IonText color="danger">{errors?.password?.message}</IonText>
                </IonCol>
              </IonRow>
              <IonRow className={styles.formItem}>
                <IonCol>
                  <IonLabel className={styles.label}>Confirm</IonLabel>
                  <IonInput
                    type={isRevealPassword ? "text" : "password"}
                    className={styles.input}
                    autocomplete="new-password"
                    placeholder="Repeat your password"
                    // @ts-ignore: Unreachable code error
                    {...register("confirmPassword", {
                      required: "Confirm password is Required!",
                    })}
                    name="confirmPassword"
                  />
                  <IonText color="danger">
                    {errors?.confirmPassword?.message}
                  </IonText>
                </IonCol>
              </IonRow>
              <IonButton
                type="submit"
                mode="ios"
                color="primary"
                className={styles.button}
                shape="round"
              >
                Continue
              </IonButton>
              <IonLoading
                isOpen={isLoading}
                message={"Loading..."}
                duration={5000}
              />
            </form>
            <IonRow>
              <Link to="/login" className={`${styles.label} ${styles.link}`}>
                Back to login
              </Link>
            </IonRow>
            <IonText color="danger">
              {(signupError as any)?.data?.message}
            </IonText>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default Signup;
