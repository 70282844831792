import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface tenantState {
  tenantId: string;
}

const initialState: tenantState = {
  tenantId: "",
};

export const tenantSlice = createSlice({
  name: "tenant",
  initialState,
  reducers: {
    setTenantId: (
      state,
      {
        payload: { tenantId },
      }: PayloadAction<{
        tenantId: string;
      }>
    ) => {
      state.tenantId = tenantId;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setTenantId } = tenantSlice.actions;

export default tenantSlice.reducer;
