import React, { useState } from "react";
import {
  IonContent,
  IonCard,
  IonCardContent,
  IonRow,
  IonInput,
  IonButton,
  IonText,
  IonLoading,
} from "@ionic/react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

/** Component */
import { RootState } from "../../../redux/store";
import { useMeQuery, useUpdateUserMutation } from "../userApi";
import { logout } from "../../auth/authSlice";

/** Style */
import styles from "./userProfile.module.css";

/** Type */
import { Role, User } from "../userType";

const UserProfile: React.FC = () => {
  const [isEditable, setIsEditable] = useState(false);
  const dispatch = useDispatch();
  const userId = useSelector((state: RootState) => state.auth.userId);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<User>();
  const [updateUser, { isLoading, error: updateUserError }] =
    useUpdateUserMutation();
  const { data: user } = useMeQuery();
  const noError = Object.keys(errors)?.length === 0;

  const onSubmit: SubmitHandler<User> = async (formData: any) => {
    try {
      const userUpdatedData = {
        id: userId ?? "",
        role: user?.role,
        ...formData,
      };
      await updateUser(userUpdatedData).unwrap();
    } catch (error) {
      console.log("error", error);
    }
  };

  const onButtonClick = () => {
    if (isEditable) {
      noError && setIsEditable(!isEditable);
    } else {
      setIsEditable(!isEditable);
    }
  };

  return (
    <IonContent className="ion-padding">
      <IonCard style={{ backgroundColor: "white" }}>
        <IonCardContent>
          <form onSubmit={handleSubmit(onSubmit)} className={styles.container}>
            <IonRow className="ion-align-items-center">
              <IonInput
                type="text"
                value={!errors?.firstName ? user?.firstName : ""}
                readonly={!isEditable}
                className={isEditable ? styles.input : ""}
                placeholder="Firstname"
                // @ts-ignore: Unreachable code error
                {...register("firstName", {
                  required: "FirstName is Required!",
                })}
              />
              <IonInput
                type="text"
                value={!errors?.lastName ? user?.lastName : ""}
                readonly={!isEditable}
                className={isEditable ? styles.input : ""}
                placeholder="Lastname"
                {...register("lastName", { required: "LastName is Required!" })}
              />
              <IonButton
                type={isEditable ? "submit" : "button"}
                mode="ios"
                color="primary"
                shape="round"
                expand="block"
                style={{ width: "30%" }}
                fill="clear"
                onClick={onButtonClick}
              >
                {isEditable ? "submit" : "edit"}
              </IonButton>
            </IonRow>
            <IonInput
              type="email"
              value={!errors?.email ? user?.email : ""}
              readonly={!isEditable}
              className={isEditable ? styles.input : ""}
              placeholder="Email"
              {...register("email", { required: "Email is Required!" })}
            />
            <IonInput
              type="number"
              value={user?.phoneNumber}
              readonly={!isEditable}
              className={isEditable ? styles.input : ""}
              placeholder="Number"
              {...register("phoneNumber")}
            />
            <IonInput
              type="text"
              value={user?.address}
              readonly={!isEditable}
              className={isEditable ? styles.input : ""}
              placeholder="Address"
              {...register("address")}
            />
            {/* <IonInput
              type="text"
              value={user?.birthDate}
              readonly={!isEditable}
              className={isEditable ? styles.input : ""}
              placeholder="Birthdate"
              {...register("birthDate")}
            /> */}
            {user?.role === Role.patient ? (
              <IonInput
                type="text"
                value={user?.insuranceId}
                readonly={!isEditable}
                className={isEditable ? styles.input : ""}
                placeholder="Insurance id"
                {...register("insuranceId")}
              />
            ) : null}
            {user?.role === Role.doctor ? (
              <IonInput
                type="number"
                value={user?.licenseNumber}
                readonly={!isEditable}
                className={isEditable ? styles.input : ""}
                // @ts-ignore: Unreachable code error
                placeholder="License number"
                {...register("licenseNumber", { valueAsNumber: true })}
              />
            ) : null}
            <IonText color="danger">{errors?.firstName?.message}</IonText>
            <IonText color="danger">{errors?.lastName?.message}</IonText>
            <IonText color="danger">{errors?.email?.message}</IonText>
            <IonText color="danger">
              {(updateUserError as any)?.data?.message}
            </IonText>
            <IonLoading
              isOpen={isLoading}
              message={"Loading..."}
              duration={5000}
            />
          </form>
        </IonCardContent>
      </IonCard>
      <IonButton
        type="submit"
        mode="ios"
        color="primary"
        shape="round"
        fill="clear"
        onClick={() => dispatch(logout())}
      >
        Logut
      </IonButton>
    </IonContent>
  );
};

export default UserProfile;
