import React, { useState } from "react";
import {
  IonRow,
  IonCol,
  IonPage,
  IonLabel,
  IonInput,
  IonText,
  IonButton,
  IonLoading,
  IonContent,
  IonIcon,
  IonButtons,
} from "@ionic/react";
import { eyeOutline, eyeOffOutline } from "ionicons/icons";
import { useForm, SubmitHandler } from "react-hook-form";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";

/** Component */
import { useLoginMutation } from "../../features/auth/authApi";
import { logout, setCredentials } from "../../features/auth/authSlice";
import { setTenantId } from "../../features/tenant/tenantSlice";
import { RootState } from "../../redux/store";

/** Style */
import LoginImg from "../../graphics/Login/login.png";
import styles from "./Login.module.css";

type Inputs = {
  email: string;
  password: string;
};

const Login: React.FC = () => {
  const [isRevealPassword, setIsRevealPassword] = useState(false);
  const refreshToken = useSelector(
    (state: RootState) => state.auth.accessToken
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [login, { isLoading, error: loginError }] = useLoginMutation();

  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    try {
      refreshToken && dispatch(logout());
      const response = await login(data).unwrap();
      if (response) {
        let decoded: any = jwt_decode(response?.accessToken);
        const data = {
          userId: decoded?.userId,
          accessToken: response.accessToken,
          refreshToken: response.refreshToken,
        };
        dispatch(setCredentials(data));
        dispatch(setTenantId({ tenantId: decoded?.tenantId }));
        push("/onboarding");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <IonPage>
      <IonContent>
        <IonRow className={styles.container}>
          <IonCol>
            <IonRow>
              <IonText className={styles.title}>
                {!refreshToken ? "Welcome" : "Welcome back"}
              </IonText>
            </IonRow>
            <form onSubmit={handleSubmit(onSubmit)}>
              <IonRow>
                <IonText className={styles.desc}>
                  Please enter your email address and password
                </IonText>
              </IonRow>
              <IonRow className={styles.formItem}>
                <IonCol>
                  <IonLabel className={styles.label}>Email</IonLabel>
                  <IonInput
                    type="email"
                    autocomplete="email"
                    className={styles.input}
                    placeholder="Enter your email"
                    // @ts-ignore: Unreachable code error
                    {...register("email", { required: "Email is Required!" })}
                    name="email"
                  />
                  <IonText color="danger">{errors?.email?.message}</IonText>
                </IonCol>
              </IonRow>
              <IonRow className={styles.formItem}>
                <IonCol>
                  <IonLabel className={styles.label}>Password</IonLabel>
                  <IonRow>
                    <IonInput
                      type={isRevealPassword ? "text" : "password"}
                      className={styles.input}
                      placeholder="Enter your password"
                      // @ts-ignore: Unreachable code error
                      {...register("password", {
                        required: "Password is Required!",
                      })}
                      name="password"
                    />
                    <IonButtons>
                      <IonButton
                        onClick={() => setIsRevealPassword(!isRevealPassword)}
                      >
                        <IonIcon
                          slot="icon-only"
                          icon={isRevealPassword ? eyeOutline : eyeOffOutline}
                        ></IonIcon>
                      </IonButton>
                    </IonButtons>
                  </IonRow>
                  <IonText color="danger">{errors?.password?.message}</IonText>
                </IonCol>
              </IonRow>
              <IonButton
                type="submit"
                mode="ios"
                color="primary"
                className={styles.button}
                shape="round"
              >
                {!refreshToken ? "Log in" : "Start over"}
              </IonButton>
              <IonLoading
                isOpen={isLoading}
                message={"Loading..."}
                duration={5000}
              />
            </form>
            <IonRow>
              <IonCol>
                <IonText
                  className={styles.label}
                  style={{ color: "var(--ion-link-color)" }}
                >
                  Don’t have an account?
                </IonText>
              </IonCol>
              <IonCol>
                <Link to="/signup" className={`${styles.label} ${styles.link}`}>
                  Sign up now
                </Link>
              </IonCol>
            </IonRow>
            <IonText color="danger">
              {(loginError as any)?.data?.message}
            </IonText>
          </IonCol>
          <IonCol className={`ion-justify-content-center ${styles.img}`}>
            <img alt="head-top-svg" src={LoginImg} />
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default Login;
