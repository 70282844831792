import React from "react";
import {
  IonPage,
  IonButton,
  IonToolbar,
  IonMenuToggle,
  IonButtons,
  IonSplitPane,
  IonIcon,
  IonSelect,
  IonSelectOption,
  // IonTitle,
} from "@ionic/react";
import { menu } from "ionicons/icons";
import { useDispatch, useSelector } from "react-redux";

/** Component */
import SidebarMenu from "../../features/menu/sidebarMenu/sidebarMenu";
import { RootState } from "../../redux/store";
import OrganizationsMenu from "../../features/organization/organizationsMenu/organizationsMenu";
import DoctorsMenu from "../../features/doctor/doctorsMenu/doctorsMenu";
import PatientsMenu from "../../features/patient/patientsMenu/patientsMenu";
import UserProfile from "../../features/user/userProfile/userProfile";
import { selectOrganization } from "../../features/organization/organizationSlice";
import { useBrowseOrganizationQuery } from "../../features/organization/organizationApi";
import { useMeQuery } from "../../features/user/userApi";

/** Type */
import { MenuTab } from "../../features/menu/menuType";
import { useHistory } from "react-router";

/** Style */
// import styles from "./Dashboard.module.css";

const Dashboard: React.FC = () => {
  const selectedMenuTab = useSelector(
    (state: RootState) => state.menu.selectedMenuTab
  );
  const selectedOrganization = useSelector(
    (state: RootState) => state.organization.selectedOrganization
  );
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { data: allOrganizations } = useBrowseOrganizationQuery({
    page: 1,
    results: 100,
  });
  const { data: user } = useMeQuery();

  const renderMenu = (status: MenuTab | undefined) => {
    switch (status) {
      case MenuTab.organization:
        return <OrganizationsMenu />;
      case MenuTab.doctor:
        return <DoctorsMenu />;
      case MenuTab.patient:
        return <PatientsMenu />;
      case MenuTab.profile:
        return <UserProfile />;
      default:
        return null;
    }
  };

  return (
    <IonPage>
      <IonSplitPane when="sm" contentId="main-content">
        <SidebarMenu />

        <div className="ion-page" id="main-content">
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuToggle>
                <IonButton>
                  <IonIcon slot="icon-only" icon={menu}></IonIcon>
                </IonButton>
              </IonMenuToggle>
            </IonButtons>
            {selectedMenuTab === MenuTab.doctor ||
            selectedMenuTab === MenuTab.patient ? (
              <IonSelect
                placeholder={
                  selectedOrganization?.name ?? "Select Organization"
                }
                style={{ width: "150px" }}
                interface="popover"
                onIonChange={(e) => {
                  dispatch(selectOrganization(e.detail.value));
                }}
              >
                {user?.organization ? (
                  <IonSelectOption
                    value={user?.organization}
                    key={user?.organization?.id}
                  >
                    {user?.organization?.name}
                  </IonSelectOption>
                ) : (
                  allOrganizations?.items?.map((organ) => (
                    <IonSelectOption value={organ} key={organ.id}>
                      {organ?.name}
                    </IonSelectOption>
                  ))
                )}
              </IonSelect>
            ) : null}
          </IonToolbar>

          {renderMenu(selectedMenuTab)}
        </div>
      </IonSplitPane>
    </IonPage>
  );
};

export default Dashboard;
