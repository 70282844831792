export interface IPhysicalExaminationModule {
  text?: string;
}

export const physicalExaminationModuleDefaultStatements = {
  heent:
    "Pupils are equal, round,and reactive to light and accommodation. Extraocular movements intact.  No icterus, no inflamed mucosa.",
  neck: "Supple, no jugular venous distention.",
  thyroid:
    "Normal limits, approximately 20 gm in size.  No nodules.  No tenderness.",
  cardiovascular: "S1 and S2, regular.",
  lungs: "Clear bilaterally.",
  abdomen: "Nontender, nondistended, positive bowel sounds.",
  extremities: "Negative for cyanosis, clubbing or edema.  No tremors.",
  skin: "Negative for dryness or diaphoresis.  No bruising.",
  psychological: "The patient is in a good mood, no anxiety.",
};
