import React from "react";
import {
  IonContent,
  IonInput,
  IonButton,
  IonLabel,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonIcon,
  IonText,
  IonLoading,
  IonRow,
  IonCol,
} from "@ionic/react";
import { useForm, SubmitHandler } from "react-hook-form";
import { closeOutline } from "ionicons/icons";

/** Component */
import { useCreateOrganizationMutation } from "../organizationApi";

/** Style */
import styles from "./createOrganizationForm.module.css";

/** Type */
import { Organization } from "../organizationType";

interface CreateOrganizationFormProps {
  openModal?: () => void;
  closeModal?: () => void;
}

const CreateOrganizationForm: React.FC<CreateOrganizationFormProps> = ({
  openModal,
  closeModal,
}: CreateOrganizationFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Organization>();
  const [createOrganization, { isLoading, error: createOrganizationError }] =
    useCreateOrganizationMutation();

  const onSubmit: SubmitHandler<Organization> = async (
    formData: Organization
  ) => {
    const filteredFormData = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => value !== "")
    );
    try {
      await createOrganization(filteredFormData).unwrap();
      closeModal?.();
    } catch (error) {}
  };

  return (
    <IonContent className="ion-padding">
      <IonToolbar>
        <IonButtons slot="end">
          <IonButton onClick={openModal}>
            <IonIcon src={closeOutline} />
          </IonButton>
        </IonButtons>
        <IonTitle>Create new organization</IonTitle>
      </IonToolbar>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.container}>
        <IonRow className={styles.formItem}>
          <IonCol>
            <IonLabel className={styles.label}>Name</IonLabel>
            <IonInput
              type="text"
              className={styles.input}
              placeholder="Choose a name"
              {...register("name", {
                required: "Name is Required!",
                minLength: {
                  value: 2,
                  message: "Name must be longer than or equal to 2 characters!",
                },
              })}
            />
            <IonText color="danger">{errors?.name?.message}</IonText>
          </IonCol>
        </IonRow>
        <IonRow className={styles.formItem}>
          <IonCol>
            <IonLabel className={styles.label}>Address</IonLabel>
            <IonInput
              type="text"
              className={styles.input}
              placeholder="Enter address"
              {...register("address")}
            />
          </IonCol>
        </IonRow>
        <IonButton
          type="submit"
          mode="ios"
          color="primary"
          shape="round"
          style={{ width: "40%", alignSelf: "center" }}
        >
          Confirm
        </IonButton>
        <IonText color="danger">
          {(createOrganizationError as any)?.data?.message}
        </IonText>
        <IonLoading isOpen={isLoading} message={"Loading..."} duration={5000} />
      </form>
    </IonContent>
  );
};

export default CreateOrganizationForm;
