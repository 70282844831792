import React from "react";
import {
  IonLabel,
  IonListHeader,
  IonItem,
  IonSpinner,
  IonButton,
  IonText,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import { Virtuoso } from "react-virtuoso";

/** Component */
import { Organization } from "../../features/organization/organizationType";
import { User } from "../../features/user/userType";

/** Style */
import styles from "./List.module.css";

interface ListProps {
  label: string;
  data?: Organization[] | User[];
  route?: string;
  organizationName?: string;
  height?: number | string;
  isLoading?: boolean;
  isEmpty?: boolean;
  error?: any;
  hasNext?: boolean;
  hasPrevious?: boolean;
  onNext?: () => void;
  onPrevious?: () => void;
  onRowButtonClick?: (item: Organization) => void;
}

const List: React.FC<ListProps> = ({
  label,
  data,
  route,
  organizationName,
  height,
  isLoading,
  isEmpty,
  error,
  hasNext,
  hasPrevious,
  onNext,
  onPrevious,
  onRowButtonClick,
}: ListProps) => {
  const { push } = useHistory();

  const Footer = () => {
    return !isEmpty ? (
      <div className={styles.empty}>
        <IonButton disabled={!hasPrevious || isLoading} onClick={onPrevious}>
          previous
        </IonButton>
        <IonButton disabled={!hasNext || isLoading} onClick={onNext}>
          next
        </IonButton>
      </div>
    ) : null;
  };

  const EmptyPlaceholder = () => {
    return error ? (
      <div className={styles.error}>
        <IonText>Something went wrong please try again!</IonText>
        <IonText>{error?.data?.message}</IonText>
      </div>
    ) : (
      <div className={styles.empty}>List is empty!</div>
    );
  };

  const onRowClick = (temp: User) => {
    push({
      pathname: `/${route}/${temp?.id}`,
      state: { user: temp },
    });
  };

  return (
    <>
      <IonListHeader>
        <IonLabel className={styles.header}>
          {`List of ${label} `}
          <IonText style={{ fontWeight: "bold" }}>{organizationName}</IonText>
        </IonLabel>
      </IonListHeader>
      {isLoading ? (
        <IonSpinner
          name="crescent"
          color="primary"
          className={styles.loading}
        />
      ) : (
        <Virtuoso
          style={{ height: height }}
          data={data}
          overscan={200}
          itemContent={(index, temp) => (
            <IonItem
              key={temp?.id}
              button={route ? true : false}
              onClick={() => route && onRowClick(temp)}
            >
              <IonLabel>
                {(temp as Organization)?.name ??
                  `${(temp as User)?.firstName} ${(temp as User)?.lastName}`}
              </IonLabel>
              {onRowButtonClick ? (
                <IonButton onClick={() => onRowButtonClick?.(temp)}>
                  select organization
                </IonButton>
              ) : null}
            </IonItem>
          )}
          components={{ EmptyPlaceholder, Footer }}
        />
      )}
    </>
  );
};

export default List;
