import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
} from "@ionic/react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useBrowseExamRecordQuery } from "../../features/examRecord/examRecordApi";
import { clearAllExamRecord } from "../../features/examRecord/examRecordSlice";
import { User } from "../../features/user/userType";

/** Style */
import styles from "./PatientDetail.module.css";

type State = {
  user?: User;
};

const PatientDetail: React.FC = () => {
  const { push } = useHistory();
  const {
    state: { user },
  }: { state: State } = useLocation();
  const dispatch = useDispatch();
  const { data: examRecords } = useBrowseExamRecordQuery({
    patientId: user?.id || "",
  });

  const onConductExamClick = () => {
    push({
      pathname: `/exams`,
      state: { patientId: user?.id },
    });
    dispatch(clearAllExamRecord());
  };

  const onViewExamClick = async (examId: string) => {
    push({
      pathname: `/patient-detail/${user?.id}/exam-record/${examId}`,
      state: {
        id: examId,
      },
    });
  };

  return (
    <IonPage>
      <IonContent>
        <IonHeader>
          <IonTitle className={styles.header}>Patient Details</IonTitle>
        </IonHeader>
        <IonRow>
          <IonCol>
            <IonItem>
              <IonLabel>Firstname</IonLabel>
              <IonLabel>{user?.firstName}</IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>Lastname</IonLabel>
              <IonLabel>{user?.lastName}</IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>Email</IonLabel>
              <IonLabel>{user?.email}</IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>Address</IonLabel>
              <IonLabel>{user?.address}</IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>Birthdate</IonLabel>
              <IonLabel>{user?.birthDate}</IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>Insurance id</IonLabel>
              <IonLabel>{user?.insuranceId}</IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>Exam Records</IonLabel>
              <IonButtons>
                <IonButton onClick={onConductExamClick} color="primary">
                  Conduct Exams
                </IonButton>
              </IonButtons>
            </IonItem>
            <IonItem>
              <IonRow>
                <IonCol style={{ paddingLeft: 0 }}>
                  <IonLabel>Review Exams Data</IonLabel>
                  {examRecords?.items?.map((temp, index) => (
                    <IonItem lines="none" key={temp?.id}>
                      <IonText>{`exam ${index}`}</IonText>
                      <IonButtons>
                        <IonButton
                          onClick={() => onViewExamClick(temp?.id)}
                          color="primary"
                        >
                          View
                        </IonButton>
                      </IonButtons>
                    </IonItem>
                  ))}
                </IonCol>
              </IonRow>
            </IonItem>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default PatientDetail;
