import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface AuthState {
  userId: string | null;
  accessToken: string | null;
  refreshToken: string | null;
}

const initialState: AuthState = {
  userId: null,
  accessToken: null,
  refreshToken: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (
      state,
      {
        payload: { userId, accessToken, refreshToken },
      }: PayloadAction<{
        userId: string;
        accessToken: string;
        refreshToken: string;
      }>
    ) => {
      state.userId = userId;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
    },
    tokenReceived: (
      state,
      {
        payload: { accessToken },
      }: PayloadAction<{
        accessToken: string;
      }>
    ) => {
      state.accessToken = accessToken;
    },
    logout: (state) => {},
  },
});

// Action creators are generated for each case reducer function
export const { setCredentials, tokenReceived, logout } = authSlice.actions;

export default authSlice.reducer;
