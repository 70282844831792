import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../../redux/customeApi";
import { BrowseUserRequest, BrowseUserResponse, User } from "./userType";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Me", "New-User"],
  endpoints: (builder) => ({
    browseUser: builder.query<BrowseUserResponse, BrowseUserRequest>({
      query: ({ organizationId, role, page, results }) => ({
        url: `users/browse-users?role=${role}${
          organizationId ? `&organizationId=${organizationId}` : ""
        }&page=${page}&results=${results}`,
      }),
      providesTags: ["New-User"],
    }),
    createUser: builder.mutation<void, User>({
      query: (body) => ({
        url: `users/create-user`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["New-User"],
    }),
    updateUser: builder.mutation<void, User>({
      query: (body) => ({
        url: `users/update-user`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Me"],
    }),
    me: builder.query<User, void>({
      query: () => `users/me`,
      providesTags: ["Me"],
    }),
  }),
});

export const {
  useBrowseUserQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useMeQuery,
} = userApi;
