export interface IReviewOfSystem {
  text?: string;
}

export const reviewOfSystemDefaultStatements = {
  consitutional: "Denies any fever or fatigue.",
  ent: "Denies any sore throat or headaches.",
  respiratory: "Denies any cough or shortness of breath.",
  cardiovascular: "Denies any chest pain, swelling of legs, or palpitations",
  gastrointestinal:
    "Denies any nausea, vomiting, diarrhea, constipation or abdominal pain.",
  genitourinary:
    "Denies any blood in the urine, pain with urination, urgency, frequency or involuntary urine loss.",
  musculoskeletal: "Denies any joint pain or swelling.",
  neurologic: "Denies any frequent/severe headaches.",
  psychological: "Denies any depression or severe anxiety.",
};
