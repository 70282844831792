import { configureStore } from "@reduxjs/toolkit";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { authApi } from "../features/auth/authApi";
import { examRecordApi } from "../features/examRecord/examRecordApi";
import { insuranceApi } from "../features/insurance/insuranceApi";
import { organizationApi } from "../features/organization/organizationApi";
import { tenantApi } from "../features/tenant/tenantApi";
import { userApi } from "../features/user/userApi";
import { rootReducer } from "./reducers";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  // whitelist: ['auth']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      authApi.middleware,
      userApi.middleware,
      tenantApi.middleware,
      organizationApi.middleware,
      insuranceApi.middleware,
      examRecordApi.middleware,
    ]),
  devTools: process.env.NODE_ENV !== "production",
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
