import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../../redux/customeApi";
import {
  SignupResponse,
  SignupRequest,
  LoginResponse,
  LoginRequest,
  LogoutRequest,
  SetPasswordResponse,
  SetPasswordRequest,
  // RefreshResponse,
  // RefreshRequest,
} from "./authType";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    signup: builder.mutation<SignupResponse, SignupRequest>({
      query: (body) => ({
        url: "auth/signup",
        method: "POST",
        body,
      }),
    }),
    login: builder.mutation<LoginResponse, LoginRequest>({
      query: (body) => ({
        url: "auth/login",
        method: "POST",
        body,
      }),
    }),
    logout: builder.mutation<void, LogoutRequest>({
      query: (body) => ({
        url: "auth/logout",
        method: "DELETE",
        body,
      }),
    }),
    setPassword: builder.mutation<SetPasswordResponse, SetPasswordRequest>({
      query: (body) => ({
        url: "auth/set-password",
        method: "POST",
        body,
      }),
    }),
    // refresh: builder.mutation<RefreshResponse, RefreshRequest>({
    //   query: (credentials) => ({
    //     url: "auth/refresh",
    //     method: "POST",
    //     body: credentials,
    //   }),
    // }),
  }),
});

export const {
  useSignupMutation,
  useLoginMutation,
  useLogoutMutation,
  useSetPasswordMutation,
  // useRefreshMutation,
} = authApi;
