import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { User } from "../user/userType";

export interface patientState {
  patinetsInOrgan: User[];
  patients: User[];
}

const initialState: patientState = {
  patinetsInOrgan: [],
  patients: [],
};

export const patientSlice = createSlice({
  name: "patient",
  initialState,
  reducers: {
    setPatients: (
      state,
      {
        payload: { items },
      }: PayloadAction<{
        // currentPage?: string;
        items?: User[];
        // resultsPerPage?: number;
        // totalPages?: number;
        // totalResults?: number;
      }>
    ) => {
      state.patients = items ?? [];
    },
    setPatientsInOrgan: (
      state,
      { payload: { items } }: PayloadAction<{ items?: User[] }>
    ) => {
      state.patinetsInOrgan = items ?? [];
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPatients, setPatientsInOrgan } = patientSlice.actions;

export default patientSlice.reducer;
