import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { User } from "../user/userType";

export interface doctorState {
  doctorsInOrgan: User[];
  doctors: User[];
}

const initialState: doctorState = {
  doctorsInOrgan: [],
  doctors: [],
};

export const doctorSlice = createSlice({
  name: "doctor",
  initialState,
  reducers: {
    setDoctors: (
      state,
      {
        payload: { items },
      }: PayloadAction<{
        // currentPage?: string;
        items?: User[];
        // resultsPerPage?: number;
        // totalPages?: number;
        // totalResults?: number;
      }>
    ) => {
      state.doctors = items ?? [];
    },
    setDoctorsInOrgan: (
      state,
      { payload: { items } }: PayloadAction<{ items?: User[] }>
    ) => {
      state.doctorsInOrgan = items ?? [];
    },
  },
});

// Action creators are generated for each case reducer function
export const { setDoctors, setDoctorsInOrgan } = doctorSlice.actions;

export default doctorSlice.reducer;
