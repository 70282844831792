import React from "react";
import {
  IonContent,
  IonInput,
  IonButton,
  IonLabel,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonIcon,
  IonText,
  IonLoading,
  IonCol,
  IonRow,
} from "@ionic/react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useSelector } from "react-redux";
import { closeOutline } from "ionicons/icons";

/** Component */
import { useCreateUserMutation } from "../userApi";
import { RootState } from "../../../redux/store";

/** Style */
import styles from "./createUserForm.module.css";

/** Type */
import { Role, User } from "../userType";

interface CreateUserFormProps {
  openModal?: () => void;
  closeModal?: () => void;
  formType?: Role;
}

const CreateUserForm: React.FC<CreateUserFormProps> = ({
  openModal,
  closeModal,
  formType,
}) => {
  const selectedOrganization = useSelector(
    (state: RootState) => state.organization.selectedOrganization
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<User>();
  const [createUser, { isLoading, error: createUserError }] =
    useCreateUserMutation();

  const onSubmit: SubmitHandler<User> = async (formData: any) => {
    const filteredFormData = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => value !== "")
    );
    try {
      await createUser({
        ...filteredFormData,
        role: formType,
        organizationId: selectedOrganization?.id,
      }).unwrap();
      closeModal?.();
    } catch (error) {
      // console.log("error", error);
    }
  };

  return (
    <IonContent className="ion-padding">
      <IonToolbar>
        <IonButtons slot="end">
          <IonButton onClick={openModal}>
            <IonIcon src={closeOutline} />
          </IonButton>
        </IonButtons>
        <IonTitle>{`Create new ${formType}`}</IonTitle>
      </IonToolbar>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.container}>
        <IonRow className={styles.formItem}>
          <IonCol>
            <IonLabel className={styles.label}>Firstname</IonLabel>
            <IonInput
              type="text"
              className={styles.input}
              placeholder="Choose a firstname"
              // @ts-ignore: Unreachable code error
              {...register("firstName", {
                required: "Firstname is Required!",
                minLength: {
                  value: 2,
                  message:
                    "FirstName must be longer than or equal to 2 characters!",
                },
              })}
            />
            <IonText color="danger">{errors?.firstName?.message}</IonText>
          </IonCol>
        </IonRow>
        <IonRow className={styles.formItem}>
          <IonCol>
            <IonLabel className={styles.label}>Lastname</IonLabel>
            <IonInput
              type="text"
              className={styles.input}
              placeholder="Choose a lastname"
              {...register("lastName", {
                required: "LastName is Required!",
                minLength: {
                  value: 2,
                  message:
                    "LastName must be longer than or equal to 2 characters!",
                },
              })}
            />
            <IonText color="danger">{errors?.lastName?.message}</IonText>
          </IonCol>
        </IonRow>
        <IonRow className={styles.formItem}>
          <IonCol>
            <IonLabel className={styles.label}>Email</IonLabel>
            <IonInput
              type="email"
              autocomplete="email"
              className={styles.input}
              placeholder="Enter your email"
              {...register("email", { required: "Email is Required!" })}
            />
            <IonText color="danger">{errors?.email?.message}</IonText>
          </IonCol>
        </IonRow>
        <IonRow className={styles.formItem}>
          <IonCol>
            <IonLabel className={styles.label}>Number</IonLabel>
            <IonInput
              type="number"
              className={styles.input}
              placeholder="Enter your phonenumber"
              {...register("phoneNumber")}
            />
          </IonCol>
        </IonRow>
        <IonRow className={styles.formItem}>
          <IonCol>
            <IonLabel className={styles.label}>Address</IonLabel>
            <IonInput
              type="text"
              className={styles.input}
              placeholder="Enter your address"
              {...register("address")}
            />
          </IonCol>
        </IonRow>
        <IonRow className={styles.formItem}>
          <IonCol>
            <IonLabel className={styles.label}>Birthdate</IonLabel>
            <IonInput
              type="date"
              className={styles.input}
              placeholder="Enter your birthdate"
              {...register("birthDate")}
            />
          </IonCol>
        </IonRow>
        {formType === Role.patient ? (
          <IonRow className={styles.formItem}>
            <IonCol>
              <IonLabel className={styles.label}>Insurance id</IonLabel>
              <IonInput
                type="text"
                className={styles.input}
                placeholder="Enter your insurance id"
                {...register("insuranceId")}
              />
            </IonCol>
          </IonRow>
        ) : null}
        {formType === Role.doctor ? (
          <IonRow className={styles.formItem}>
            <IonCol>
              <IonLabel className={styles.label}>License number</IonLabel>
              <IonInput
                type="number"
                className={styles.input}
                placeholder="Enter your License number"
                {...register("licenseNumber", {
                  valueAsNumber: true,
                  required: "LicenseNumber is Required!",
                })}
              />
              <IonText color="danger">{errors?.licenseNumber?.message}</IonText>
            </IonCol>
          </IonRow>
        ) : null}
        <IonButton
          type="submit"
          mode="ios"
          color="primary"
          shape="round"
          style={{ width: "40%", alignSelf: "center" }}
          disabled={isLoading}
        >
          Confirm
        </IonButton>
        <IonText color="danger">
          {(createUserError as any)?.data?.message}
        </IonText>
        <IonLoading isOpen={isLoading} message={"Loading..."} duration={5000} />
      </form>
    </IonContent>
  );
};

export default CreateUserForm;
