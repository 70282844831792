import { combineReducers } from "@reduxjs/toolkit";
import { authApi } from "../features/auth/authApi";
import authSlice, { logout } from "../features/auth/authSlice";
import doctorSlice from "../features/doctor/doctorSlice";
import { examRecordApi } from "../features/examRecord/examRecordApi";
import examRecordSlice from "../features/examRecord/examRecordSlice";
import { insuranceApi } from "../features/insurance/insuranceApi";
import menuSlice from "../features/menu/menuSlice";
import { organizationApi } from "../features/organization/organizationApi";
import organizationSlice from "../features/organization/organizationSlice";
import patientSlice from "../features/patient/patientSlice";
import { tenantApi } from "../features/tenant/tenantApi";
import tenantSlice from "../features/tenant/tenantSlice";
import { userApi } from "../features/user/userApi";
import userSlice from "../features/user/userSlice";

export const appReducer = combineReducers({
  auth: authSlice,
  menu: menuSlice,
  tenant: tenantSlice,
  organization: organizationSlice,
  user: userSlice,
  doctor: doctorSlice,
  patient: patientSlice,
  examRecord: examRecordSlice,
  [authApi.reducerPath]: authApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [tenantApi.reducerPath]: tenantApi.reducer,
  [organizationApi.reducerPath]: organizationApi.reducer,
  [insuranceApi.reducerPath]: insuranceApi.reducer,
  [examRecordApi.reducerPath]: examRecordApi.reducer,
});

export const rootReducer = (state: any, action: any) => {
  if (logout.match(action)) {
    state = undefined;
  }

  return appReducer(state, action);
};
