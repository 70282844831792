import React, { useRef, useState } from "react";
import { IonButton, IonContent, IonModal, IonTitle } from "@ionic/react";
import { useSelector } from "react-redux";

/** Component */
import List from "../../../components/List/List";
import CreateUserForm from "../../user/createUserForm/createUserForm";
import { useBrowseUserQuery, useMeQuery } from "../../user/userApi";
import { RootState } from "../../../redux/store";

/** Type */
import { Role } from "../../user/userType";

/** Style */
import styles from "./patientsMenu.module.css";

const PatientsMenu: React.FC = () => {
  const modal = useRef<HTMLIonModalElement>(null);
  const input = useRef<HTMLIonInputElement>(null);
  const [dataPage, setDataPage] = useState(1);
  const [dataInOrganPage, setDataInOrganePage] = useState(1);
  const selectedOrganization = useSelector(
    (state: RootState) => state.organization.selectedOrganization
  );
  const { data, isLoading, error } = useBrowseUserQuery({
    role: Role.patient,
    page: dataPage,
    results: 10,
  });
  const {
    data: dataInOrgan,
    isLoading: isLoadingInOrgan,
    error: errorInOrgan,
  } = useBrowseUserQuery({
    role: Role.patient,
    organizationId: selectedOrganization?.id,
    page: dataInOrganPage,
    results: 10,
  });
  const { data: user } = useMeQuery();

  const openModal = () => {
    modal.current?.dismiss();
  };

  const closeModal = () => {
    modal.current?.dismiss(input.current?.value, "confirm");
  };

  const onNext = () => {
    if (dataPage < (data?.totalPages as number)) {
      setDataPage((page) => page + 1);
    }
  };

  const onPrevious = () => {
    if (dataPage > 1) {
      setDataPage((page) => page - 1);
    }
  };

  const onNextInOrgan = () => {
    if (dataInOrganPage < (data?.totalPages as number)) {
      setDataInOrganePage((page) => page + 1);
    }
  };

  const onPreviousInOrgan = () => {
    if (dataInOrganPage > 1) {
      setDataInOrganePage((page) => page - 1);
    }
  };

  return (
    <>
      <IonContent className="ion-padding">
        <div className={styles.container}>
          <div>
            {selectedOrganization ? (
              <List
                label="patients in"
                data={dataInOrgan?.items}
                route="patient-detail"
                organizationName={selectedOrganization?.name}
                height={window.innerHeight / 3.5}
                isLoading={isLoadingInOrgan}
                isEmpty={dataInOrgan?.items?.length === 0}
                error={errorInOrgan}
                hasNext={dataInOrganPage < (data?.totalPages as number)}
                hasPrevious={dataInOrganPage > 1}
                onNext={onNextInOrgan}
                onPrevious={onPreviousInOrgan}
              />
            ) : (
              <IonTitle>
                Select an organization to view patients in them
              </IonTitle>
            )}
            {user?.role === Role.backOfficeAdmin ? (
              <List
                label="all patients"
                data={data?.items}
                route="patient-detail"
                height={window.innerHeight / 3.5}
                isLoading={isLoading}
                isEmpty={data?.items?.length === 0}
                error={error}
                hasNext={dataPage < (data?.totalPages as number)}
                hasPrevious={dataPage > 1}
                onNext={onNext}
                onPrevious={onPrevious}
              />
            ) : null}
          </div>
          <IonButton
            id="open-modal"
            type="submit"
            mode="ios"
            color="primary"
            shape="round"
            className={styles.button}
          >
            Create
          </IonButton>
        </div>
        <IonModal ref={modal} trigger="open-modal">
          <CreateUserForm
            {...{ openModal, closeModal }}
            formType={Role.patient}
          />
        </IonModal>
      </IonContent>
    </>
  );
};

export default PatientsMenu;
