import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../../redux/customeApi";
import {
  BrowseOrganizationRequest,
  BrowseOrganizationResponse,
  Organization,
} from "./organizationType";

export const organizationApi = createApi({
  reducerPath: "organizationApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Organs"],
  endpoints: (builder) => ({
    browseOrganization: builder.query<
      BrowseOrganizationResponse<Organization>,
      BrowseOrganizationRequest
    >({
      query: ({ page, results }) => ({
        url: `organization/browse-organizations?page=${page}&results=${results}`,
      }),
      providesTags: ["Organs"],
      // serializeQueryArgs: ({ endpointName }) => {
      //   return endpointName;
      // },
      // // Always merge incoming data to the cache entry
      // merge: (currentCache, newItems) => {
      //   currentCache.items.push(...newItems.items);
      // },
      // // Refetch when the page arg changes
      // forceRefetch({ currentArg, previousArg }) {
      //   return currentArg !== previousArg;
      // },
    }),
    createOrganization: builder.mutation<void, Organization>({
      query: (body) => ({
        url: `organization/create-organization`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Organs"],
    }),
    updateOrganization: builder.mutation<any, any>({
      query: (body) => ({
        url: `organization/update-organization`,
        method: "POST",
        body,
      }),
    }),
    deleteOrganization: builder.mutation<any, any>({
      query: (body) => ({
        url: `organization/delete-organization`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useBrowseOrganizationQuery,
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
  useDeleteOrganizationMutation,
} = organizationApi;
