import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../../redux/customeApi";
import {
  BrowseExamRecordDetailRequest,
  BrowseExamRecordDetailResponse,
  BrowseExamRecordRequest,
  BrowseExamRecordResponse,
  CreateExamRecordRequest,
} from "./examRecordType";

export const examRecordApi = createApi({
  reducerPath: "examRecordApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Exams"],
  endpoints: (builder) => ({
    browseExamRecord: builder.query<
      BrowseExamRecordResponse<{id: string}>,
      BrowseExamRecordRequest
    >({
      query: ({ patientId }) => ({
        url: `examrecords/browse-exam-records?patientId=${patientId}&page=1&results=100`,
      }),
      providesTags: ["Exams"],
    }),
    browseExamRecordDetail: builder.query<
      BrowseExamRecordDetailResponse,
      BrowseExamRecordDetailRequest
    >({
      query: ({ id }) => ({
        url: `examrecords/get-detail-exam-record?id=${id}`,
      }),
    }),
    createExamRecord: builder.mutation<void, CreateExamRecordRequest>({
      query: (body) => ({
        url: `examrecords/create-exam-record`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Exams"],
    }),
  }),
});

export const {
  useBrowseExamRecordQuery,
  useBrowseExamRecordDetailQuery,
  useCreateExamRecordMutation,
} = examRecordApi;
