import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../../redux/customeApi";

export const tenantApi = createApi({
  reducerPath: "tenantApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    createTenant: builder.mutation<any, any>({
      query: (body) => ({
        url: `tenant/create-tenant`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useCreateTenantMutation } = tenantApi;
