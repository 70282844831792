import React from "react";
import {
  IonLabel,
  IonList,
  IonListHeader,
  IonItem,
  IonMenu,
  IonMenuToggle,
  IonAvatar,
} from "@ionic/react";
import { useDispatch } from "react-redux";

/** Component */
import { selectMenuTab } from "../menuSlice";
import { useMeQuery } from "../../user/userApi";

/** Type */
import { MenuTab } from "../menuType";
import { Role } from "../../user/userType";

/** Style */
import styles from "./sidebarMenu.module.css";

const SiderbarMenu: React.FC = () => {
  const dispatch = useDispatch();
  const { data: user } = useMeQuery();

  const onMenuTabClick = (tab: MenuTab) => {
    dispatch(selectMenuTab(tab));
  };

  return (
    <>
      <IonMenu contentId="main-content">
        <IonList className={styles.container} inset>
          <IonListHeader>Americana Cloud</IonListHeader>
          <IonMenuToggle autoHide={false} className={styles.container}>
            <div>
              {user?.role === Role.backOfficeAdmin ? (
                <>
                  <IonItem
                    button
                    onClick={() => onMenuTabClick(MenuTab.organization)}
                  >
                    <IonLabel>Organizations</IonLabel>
                  </IonItem>
                  <IonItem
                    button
                    onClick={() => onMenuTabClick(MenuTab.doctor)}
                  >
                    <IonLabel>Doctors</IonLabel>
                  </IonItem>
                </>
              ) : null}
              <IonItem button onClick={() => onMenuTabClick(MenuTab.patient)}>
                <IonLabel>Patients</IonLabel>
              </IonItem>
            </div>
            <IonItem
              className={styles.userPofile}
              button
              onClick={() => onMenuTabClick(MenuTab.profile)}
            >
              <IonAvatar className={styles.avatar}>
                <img
                  alt="Silhouette of a person's head"
                  src="https://ionicframework.com/docs/img/demos/avatar.svg"
                />
              </IonAvatar>
              <div>
                <p className={styles.userName}>
                  {user?.firstName ?? user?.email}
                </p>
                <p className={styles.userName}>
                  {user?.role === Role.backOfficeAdmin ? "admin" : user?.role}
                </p>
              </div>
            </IonItem>
          </IonMenuToggle>
        </IonList>
      </IonMenu>
    </>
  );
};

export default SiderbarMenu;
