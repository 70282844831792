import React from "react";
import { IonRow, IonCol, IonPage, IonButton } from "@ionic/react";
import { useHistory } from "react-router-dom";

/** Style */
import styles from "./Onboarding.module.css";

const Onboarding: React.FC = () => {
  const { push } = useHistory();

  const onTenantClick = () => {
    push("/")
  }

  return (
    <IonPage>
      <IonRow className={styles.container}>
        <IonCol>
          <IonButton
            // mode="ios"
            color="primary"
            shape="round"
            onClick={onTenantClick}
          >
            Your Tenant
          </IonButton>
        </IonCol>
        {/* <IonCol>
          <IonButton
            // mode="ios"
            color="primary"
            shape="round"
          >
            Create New tenant
          </IonButton>
        </IonCol> */}
      </IonRow>
    </IonPage>
  );
};

export default Onboarding;
