import React, { useState } from "react";
import {
  IonRow,
  IonCol,
  IonPage,
  IonLabel,
  IonInput,
  IonText,
  IonButton,
  IonLoading,
  IonContent,
  IonButtons,
  IonIcon,
} from "@ionic/react";
import { eyeOutline, eyeOffOutline } from "ionicons/icons";
import { useForm, SubmitHandler } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";

/** Component */
import { useSetPasswordMutation } from "../../features/auth/authApi";

/** Style */
import styles from "./ResetPassword.module.css";

type Inputs = {
  password: string;
  confirmPassword: string;
  verifyCode: number;
};

const Signup: React.FC = () => {
  const [isRevealPassword, setIsRevealPassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();
  const { push } = useHistory();
  let { id }: { id: string } = useParams();
  const [setPassword, { isLoading, error: setPasswordError }] =
    useSetPasswordMutation();

  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    const { password, confirmPassword, verifyCode } = data;

    if (password !== confirmPassword) {
      alert("Passwords don't match");
    } else {
      try {
        const requestData = {
          userId: id,
          password,
          verifyCode,
        };
        await setPassword(requestData).unwrap();
        push("/login");
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  return (
    <IonPage>
      <IonContent>
        <IonRow className={styles.container}>
          <IonCol className={styles.col}>
            <IonRow>
              <IonText className={styles.title}>Set your password</IonText>
            </IonRow>
            <form onSubmit={handleSubmit(onSubmit)}>
              <IonRow className={styles.formItem}>
                <IonCol>
                  <IonLabel className={styles.label}>Password</IonLabel>
                  <IonRow>
                    <IonInput
                      type={isRevealPassword ? "text" : "password"}
                      className={styles.input}
                      autocomplete="new-password"
                      placeholder="Enter your password"
                      // @ts-ignore: Unreachable code error
                      {...register("password", {
                        required: "Password is Required!",
                      })}
                      name="password"
                    />
                    <IonButtons>
                      <IonButton
                        onClick={() => setIsRevealPassword(!isRevealPassword)}
                      >
                        <IonIcon
                          slot="icon-only"
                          icon={isRevealPassword ? eyeOutline : eyeOffOutline}
                        ></IonIcon>
                      </IonButton>
                    </IonButtons>
                  </IonRow>
                  <IonText color="danger">{errors?.password?.message}</IonText>
                </IonCol>
              </IonRow>
              <IonRow className={styles.formItem}>
                <IonCol>
                  <IonLabel className={styles.label}>Confirm</IonLabel>
                  <IonInput
                    type={isRevealPassword ? "text" : "password"}
                    className={styles.input}
                    autocomplete="new-password"
                    placeholder="Repeat your password"
                    // @ts-ignore: Unreachable code error
                    {...register("confirmPassword", {
                      required: "ConfirmPassword is Required!",
                    })}
                    name="confirmPassword"
                  />
                  <IonText color="danger">
                    {errors?.confirmPassword?.message}
                  </IonText>
                </IonCol>
              </IonRow>
              <IonRow className={styles.formItem}>
                <IonCol>
                  <IonLabel className={styles.label}>
                    Verification code
                  </IonLabel>
                  <IonInput
                    type="number"
                    className={styles.input}
                    placeholder="Enter your code"
                    // @ts-ignore: Unreachable code error
                    {...register("verifyCode", {
                      valueAsNumber: true,
                      required: "Verification code is Required!",
                    })}
                    name="verifyCode"
                  />
                  <IonText color="danger">
                    {errors?.verifyCode?.message}
                  </IonText>
                </IonCol>
              </IonRow>
              <IonButton
                type="submit"
                mode="ios"
                color="primary"
                className={styles.button}
                shape="round"
              >
                Continue
              </IonButton>
              <IonLoading
                isOpen={isLoading}
                message={"Loading..."}
                duration={5000}
              />
            </form>

            <IonText color="danger">
              {(setPasswordError as any)?.data?.message}
            </IonText>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default Signup;
